import React from 'react'
import Layout from "../components/layout"
import BooksCanvas from '../components/books-canvas'


const IndexPage = ({ data }) => (
  <Layout>
    <BooksCanvas data={data} />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query AllBookImages {
    allFile(filter: {relativePath: {glob: "content/books/**/cela_kniha/**"}}) {
      nodes {
        relativePath
        childImageSharp {
          fluid {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`