import * as THREE from 'three'
import React, { Suspense, useState, useRef } from 'react'
import Books from "../components/books"

import { Canvas, useFrame } from 'react-three-fiber'
import StateContext from './state-context'



const BooksCanvas = ({ data }) => (
  <StateContext.Consumer>
    {({ state, setState }) => {
      return (
        <Canvas
          onCreated={({ gl }) => {
            // Comment the next line: colors are good, hightlights blown out
            // gl.toneMapping = THREE.ACESFilmicToneMapping
            // gl.toneMapping = THREE.ACESFilmicToneMapping
            // gl.toneMapping = THREE.ACESFilmicToneMapping
            gl.toneMapping = THREE.LinearToneMapping
            // gl.toneMapping = THREE.CineonToneMapping
            gl.outputEncoding = THREE.sRGBEncoding
          }}
          orthographic={true}
          // camera={{ fov: 160, near: 0.1, far: 1000, z: 7 }}
          camera={{ near: 0.0001, far: 10000, z: -1000 }}
          style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
        >
          <ambientLight intensity={0.1} />
          <pointLight position={[0, 0, 100,]} intensity={0.8} />
          <Suspense fallback={null}>
            <Books
              data={data}
              scale={[120, 120, 120]}
              state={state}
              setState={setState}
            />
          </Suspense>
        </Canvas>

      )
    }}

  </StateContext.Consumer>
)

export default BooksCanvas
